<template>
  <div>
    <v-container>
      <v-row
          align="center"
          justify="end"
      >
        <v-col
            cols="12"
        >
          <v-btn class="add-new-btn mb-5" block rounded color="primary" small @click="redirectToLineNotify">
            前往綁定
          </v-btn>
          <v-btn class="add-new-btn mb-5" block rounded color="primary" small @click="redirectToUnbindLineNotify">
            取消綁定
          </v-btn>
          <template>
            <div>
              <div v-for="item in items" :key="item.id" class="card">
                <div class="card-header">
                  {{ item.display_name }}
                </div>
                <div class="card-body">
                  <p class="card-text">
                    通知類型: {{ item.target_type === 'USER' ? '個人通知' : '群組通知' }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import storage from 'libs/storage/localStorage.js'

export default {
  async created() {
    const res = await this.$api.collection.providerApi.getLineNotifyClientId();
    this.lineNotifyClientId = res['lineNotifyClientId'];
    const fromStorageStoreId = storage().get('chooseStoreId');
    const response = await this.$api.collection.storeApi.getLineNotifyListByStoreId(fromStorageStoreId);
    this.items = response.data;
  },
  methods: {
    redirectToLineNotify() {
      this.$root.gaLogEvent('商店_點擊_綁定Notify')
      const fromStorageStoreId = storage().get('chooseStoreId');
      const currentURL = window.location.origin + '/line-relation';
      const providerId = window.location.pathname.split('/')[2]
      window.location.href = `https://notify-bot.line.me/oauth/authorize?response_type=code&redirect_uri=${encodeURIComponent(currentURL)}&scope=notify&state=${fromStorageStoreId},${providerId}&client_id=${this.lineNotifyClientId}`;
    },
    redirectToUnbindLineNotify() {
      this.$root.gaLogEvent('供應商_點擊_解除綁定Notify')
      window.location.href = `https://notify-bot.line.me/my/`;
    }
  },
  data() {
    return {
      lineNotifyClientId: null,
      items: []
    };
  }
};
</script>
<style>
.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-header {
  font-weight: bold;
}

.card-body {
  margin-top: 10px;
}

.card-text {
  margin-bottom: 10px;
}

.v-btn:not(.v-btn--round).v-size--small {
  height: 36px;
}
</style>